import datetimePicker from "./DatetimePicker";
import datePicker from "./DatePicker";
import SingleImageCropper from './SingleImageCropper'
import SingleImageSaver from "./SingleImageSaver";
import Gallery from "./Gallery";
import Editor from "./Editor";
import Dropzone from "./Dropzone";
import phoneMask from "./PhoneMask";
import {initBlock} from "./Blocks";

export default function Load(element) {
    element.querySelectorAll('[single-image-saver]').forEach(input => {
        new SingleImageSaver(input)
    })
    element.querySelectorAll('[single-image-cropper]').forEach(input => {
        new SingleImageCropper(input)
    })
    element.querySelectorAll('[gallery]').forEach(input => {
        new Gallery(input)
    })
    element.querySelectorAll('[editor]').forEach(input => {
        new Editor(input)
    })
    element.querySelectorAll('[dropzone]').forEach(input => {
        new Dropzone(input)
    })
    element.querySelectorAll('[phone-mask]').forEach(input => {
        phoneMask(input)
    })
    element.querySelectorAll('[datetime-picker]').forEach(input => {
        datetimePicker(input)
    })
    element.querySelectorAll('[date-picker]').forEach(input => {
        datePicker(input)
    })
    element.querySelectorAll('[block-container]').forEach(container => {
        initBlock(container)
    })
}
