import './components/Preloader'
import './components/Notify'
import './components/Tabs'
import './components/ImageCropper'
import './components/ImageSaver'
import './components/MapLocationSelector'

import Load from "./components/Loader"

Load(document)
