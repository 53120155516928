function getPointer(lat, lon) {
    return new ymaps.GeoObject({
        geometry: {
            type: "Point",
            coordinates: [lat, lon],
        },
    }, {
        iconLayout: 'default#image',
        iconImageHref: '/admin/static/images/pin.png',//81d0967d-599f-457e-a9e3-d1ea5a6f448d
        iconImageSize: [30, 36],
        draggable: true
    });
}

//yaMaps
function setCoords(lat, lng) {
    document.getElementById('lat').value = lat
    document.getElementById('lng').value = lng
    setAddress(lat, lng);
}

//Определяет адрес и заносит в инпут
function setAddress(lat, lng) {
    ymaps.geocode([lat, lng]).then(
        function (res) {
            document.getElementById('address').value = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData.text
        }
    );
}

function movePointer(map, e) {
    let coords = e.get('coords'),
        lat = coords[0],
        lng = coords[1];
    map.geoObjects.removeAll();
    map.geoObjects.add(getPointer(lat, lng));
    setCoords(lat, lng);
}

function dragPointer(pointer) {
    let coords = pointer.geometry.getCoordinates();
    setCoords(coords[0], coords[1]);
}

function init() {
    let blockWithMap = document.getElementById('map')
    let lat = blockWithMap.dataset.lat,
        lon = blockWithMap.dataset.lng,
        zoom = blockWithMap.dataset.zoom,
        map = new ymaps.Map("map", {
            center: [lat, lon],
            zoom: zoom
        }),
        pointer = getPointer(lat, lon);

    setCoords(lat, lon);
    map.geoObjects.add(pointer);
    map.events.add('click', function (e) {
        movePointer(map, e);
    });
    pointer.events.add('dragend', function () {
        dragPointer(pointer);
    });

    let searchControl = map.controls.get('searchControl');
    searchControl.events.add('resultselect', function () {
        let index = searchControl.getSelectedIndex()
        let results = searchControl.getResultsArray()
        if (results.length) {
            let result = results[index]
            let coords = result.geometry.getCoordinates()
            setCoords(coords[0], coords[1])
            map.geoObjects.removeAll()
            map.geoObjects.add(getPointer(coords[0], coords[1]))
        }
    })
}

document.addEventListener('DOMContentLoaded', () => {
    let blockWithMap = document.getElementById('map')

    if (blockWithMap) {
        ymaps.ready(init);
    }
})
